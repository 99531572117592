<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    :loading="loading"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template slot="items" slot-scope="{ item }">
      <tr>
        <td
          class="justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.title }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
      </tr>
    </template>
  </crud-list>
</template>
<script>
import ContratoService from "@/services/ContratoService";

export default {
  data: () => ({
    totalRegistros: 0,
    pagination: {},
    items: [],
    loading: false
  }),
  computed: {
    tituloPesquisa: function() {
      return this.$i18n.t("ContratoList.tituloPesquisa");
    },
    headers: function() {
      return [
        {
          text: this.$i18n.t("ContratoList.title"),
          value: "title",
          sortable: false
        }
      ];
    }
  },
  methods: {
    buscar() {
      this.loading = true;
      ContratoService.findAll(this.pagination)
        .then(contratos => {
          if (contratos && contratos.data && contratos.data.rows) {
            this.items = contratos.data.rows;
            this.totalRegistros = contratos.data.count;
          }
        })
        .catch(err => {
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao:
              "Erro ao buscar os contratos cadastrados: \n" + err
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    novo() {
      this.$router.push({
        path: "/contrato/new"
      });
    },
    editar(item) {
      if (item && item.id) {
        const url = "/contrato/" + item.id;
        this.$router.push(url);
      }
    }
  }
};
</script>
