import Api from '@/services/Api'

export default {
  findAll (filter) {
    return Api().post('contrato/page', filter)
  },
  insert (contrato) {
    return Api().post('contrato', contrato)
  },
  update (contrato) {
    return Api().put('contrato', contrato)
  },
  delete (contrato) {
    return Api().delete(`contrato?id=${contrato.id}`, contrato)
  },
  findById (contrato) {
    const url = `contrato/${contrato.id}`
    return Api().get(url)
  }
}
